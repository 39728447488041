


import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { EventAccessType, TEvent } from '@/_types/event.type';
import { Validations } from 'vuelidate-property-decorators';
import { TVuelidateRuleSet } from '@/_types/vuelitation-rule-set.type';
import { TTimezoneInfo } from '@/_types/timezone-info.type';
import { TranslateResult } from 'vue-i18n';
import { Route } from 'vue-router';
import { NavigationGuardNext } from 'vue-router/types/router';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import { TEventSettings } from '@/_types/event-settings.type';
import {TGetEventSettingsParams, TPatchEventSettingsParams} from '@/_modules/events/api/event/event.api';
import { required } from 'vuelidate/lib/validators';
import _cloneDeep from 'lodash.clonedeep';
import DateTimeHelper from '@/_helpers/date-time.helper';
import timezone from '@/_modules/events/components/timezones.json';
import DatepickerHelper, { TMuseUIDatepickerDateTimeFormat } from '@/_helpers/datepicker.helper';
import EventLanguageSelector from '@/_modules/events/components/event-language-selector/event-language-selector.vue';
import ErrorInfo from '@/_modules/error-info/error-info.vue';
import { MENU_PROPERTIES_KEY_NAME } from '@/_modules/promo-cabinet/components/cabinet-event-settings/cabinet-event-settings.vue';
import { TSideBarMenuItemProperties, TSideBarMenuItems } from '@/_components/side-bar-menu-manager/side-bar-menu-manager.vue';
// @ts-ignore
import Statistics from '@/services/statistics.js';

type TCreateEventFormData = {
  title: string;
  date_start: Date;
  date_end: Date;
  access_type: EventAccessType;
  languages: string[];
  time_region: string;
}

let prevStartDate: Date = null;

const isValidStartDateNotInPast = (startDate: Date): boolean => { // TODO: move this to helper
  if (startDate === null || startDate === undefined) {
    return true;
  }

  const currentDate = new Date().getTime() / 1000;
  const dateStart = new Date(startDate).getTime() / 1000;

  prevStartDate = startDate;

  return +currentDate < dateStart;
};

const isValidEndDateNotInPast = (endDate: Date): boolean => { // TODO: move this to helper

  if ((prevStartDate === null || prevStartDate === undefined) && (endDate === null || endDate === undefined)) {
    return true;
  }

  if (prevStartDate && endDate) {
    const _startDate: number = new Date(prevStartDate).getTime() / 1000;
    const _dateEnd: number = new Date(endDate).getTime() / 1000;

    return _dateEnd > _startDate;
  } else {
    return true;
  }
};

const prevRouteInfoForStats: { to: Route; from: Route; next?: NavigationGuardNext } = { to: null, from: null, next: null };

@Component({
  components: {
    EventLanguageSelector,
    ErrorInfo,
  },
})
export default class CreateEvent extends Vue {

  @Getter('_eventStore/eventError') eventError: ApiErrorResponseData;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;

  @Action('_eventStore/getEventSettings') getEventSettings: (params: TGetEventSettingsParams) => Promise<void>;
  @Action('_eventStore/patchEventSettings') patchEventSettingsAction: (params: TPatchEventSettingsParams) => Promise<void>;

  @Validations()
  public readonly validations: TVuelidateRuleSet<TCreateEventFormData> = {
    formData: {
      title: {
        required,
      },
      date_start: {
        required,
        isValidStartDate(date_start: Date): boolean {
          return isValidStartDateNotInPast(date_start);
        }
      },
      date_end: {
        required,
        isValidEndDate(date_end: Date): boolean {
          return isValidEndDateNotInPast(date_end);
        }
      },
      time_region: {
        required,
      }
    },
  };
  public timezone: TTimezoneInfo[] = timezone;

  public formData: TCreateEventFormData = {
    title: '',
    date_start: null,
    date_end: null,
    access_type: EventAccessType.REGISTER,
    languages: ['en'],
    time_region: ''
  };

  public defaultScreen: string = '';

  public menuItems: TSideBarMenuItems = {
    // 'event_info': {
    //   properties: {
    //     isShown: true,
    //   },
    //   title: this.$t('sideBar.info') as string,
    //   iconComponentName: 'icon-home',
    // },
    promo_program: {
      properties: {
        isShown: true,
        isSorted: true,
        sorting: 1,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.program') as string,
      iconComponentName: 'icon-program',
    },
    promo_contacts: {
      properties: {
        isShown: true,
        isSorted: true,
        sorting: 2,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.contacts') as string,
      iconComponentName: 'icon-contacts',
    },
    promo_page_events_companies: {
      properties: {
        isShown: false,
        isSorted: true,
        sorting: 3,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.hall') as string,
      iconComponentName: 'icon-hall',
    },
    promo_live: {
      properties: {
        isShown: false,
        isSorted: true,
        sorting: 4,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.live') as string,
      iconComponentName: 'icon-info',
    },
    text_chats: {
      properties: {
        isShown: false,
        isSorted: true,
        sorting: 5,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.textChats') as string,
      iconComponentName: 'icon-text-chats',
    },
    news: {
      properties: {
        isShown: false,
        isSorted: true,
        sorting: 6,
      },
      title: this.$t('sideBar.news') as string,
      iconComponentName: 'icon-news',
    },
    notes_list: {
      properties: {
        isShown: false,
        isSorted: true,
        sorting: 7,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.notes') as string,
      iconComponentName: 'icon-notes',
    },
    promo_page_calendar: {
      properties: {
        isShown: false,
        isSorted: false,
        sorting: 8,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.calendar') as string,
      iconComponentName: 'icon-meetings',
    },
    result: {
      properties: {
        isShown: false,
        isSorted: false,
        sorting: 9,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.result') as string,
      iconComponentName: 'icon-result',
    },
  };

  public endDateMinimum: Date = new Date();

  public isStartDatePickerVisible: boolean = false;
  public isEndDatePickerVisible: boolean = false;
  public isTimezoneLocationSelectFocused: boolean = false;
  public isMultipleLanguagesModeChosen: boolean = false;
  public isSentSuccessfully: boolean = false;
  public createdHintText: TranslateResult = '';
  public timezoneLocation: TTimezoneInfo = {} as TTimezoneInfo;
  public isSaveEventButtonDisabled: boolean = false;

  public get menuItemKeyNames(): string[] {
    return Object.keys(this.menuItems);
  }

  @Watch('timezoneLocation', { immediate: true })
  private onTimezoneLocationChanged(): void {
    if (this.timezoneLocation && !this.timezoneLocation.utc) {
      return;
    }
    this.formData.time_region = this.timezoneLocation.utc[0];
  }

  // N.B. method is not unused, even if your IDE shows is as such! Do not delete.
  public beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    prevRouteInfoForStats.to = to;
    prevRouteInfoForStats.from = from;
    next();
  }

  private autoSetEndDate(val: Date): void {
    const start: number = new Date(this.formData.date_start).getTime() / 1000;
    const end: number = new Date(this.formData.date_end).getTime() / 1000;
    // minimum end date is 1 hour ahead, used in the :min-date prop
    this.endDateMinimum = new Date(this.formData.date_start.getTime() + 60 * 60 * 1000);

    // val has to be present and be a JS Date object. Simple check using getTime
    if (val && ('getTime' in val)) {
      if (!this.formData.date_end || start > end) {
        const date_end = new Date(val);
        date_end.setHours(23, 59, 59);

        // If auto-set date_end time is closer than 1 hour, move it to next day 23:59
        if (date_end.getTime() - val.getTime() < (60 * 60 * 1000)) {
          date_end.setTime(date_end.getTime() + 1000 * 60 * 60 * 24);
        }

        this.formData.date_end = date_end;
      }
    }
  }

  private onEndDateChange(val: Date): void {
    // Force minimum event duration to be 1 hour
    if (val.getTime() - this.formData.date_start.getTime() < 60 * 60 * 1000) {
      this.formData.date_end = new Date(this.formData.date_start.getTime() + 60 * 60 * 1000);
    }
  }

  public getCalendarDateTimeFormat(): TMuseUIDatepickerDateTimeFormat {
    return DatepickerHelper.getMuseUIDatepickerDateTimeFormat();
  }

  public firstDayOfWeek(): number {
    return DatepickerHelper.getFirstDayOfWeekNumber();
  }

  private handleEventLanguagesUpdated(eventPayload: string[]): void {
    this.formData.languages = [...eventPayload];
  }

  private showMultipleLanguages(event: Event): void {
    this.isMultipleLanguagesModeChosen = (event.target as HTMLInputElement).value === 'show';
  }

  public async patchEventSettings(eventId: number): Promise<void> {
    const preparedSettings: { [key: string]: TSideBarMenuItemProperties } = {};
    for (let i = 0; i < this.menuItemKeyNames.length; i++) {
      preparedSettings[this.menuItemKeyNames[i]] = this.menuItems[this.menuItemKeyNames[i]].properties;
    }
    let result: { [key: string]: any } = {
      [MENU_PROPERTIES_KEY_NAME]: preparedSettings
    };

    // TODO: do we have eventSettings if we are only creating a new event?
    if (this.eventSettings && this.eventSettings.layout) {
      result = { ..._cloneDeep(this.eventSettings.layout), ...result };

      if (this.defaultScreen) {
        result.defaultScreen = !this.menuItems[this.defaultScreen].properties.isShown ? '' : this.defaultScreen;
      } else {
        result.defaultScreen = '';
      }

    }

    result[MENU_PROPERTIES_KEY_NAME] = Object.keys(this.menuItems).reduce(
      (obj: any, key: any) => {
        obj[key] = this.menuItems[key];
        return obj;
      },
      {}
    );

    await this.patchEventSettingsAction({
      eventId: eventId,
      layout: result,
    });
  }

  private async createEvent(): Promise<void> {
    const ref = localStorage.getItem('referrer_url');
    this.isSaveEventButtonDisabled = true;

    this.$v.formData.$touch();

    if (!this.formData || this.$v.formData.$pending || this.$v.formData.$invalid) {
      this.isSaveEventButtonDisabled = false;
      return;
    }

    const createData = Object.assign({}, this.formData, {
      date_start: DateTimeHelper.dateToApiDate(this.formData.date_start),
      date_end: DateTimeHelper.dateToApiDate(this.formData.date_end),
      ref,
      restrictions: [
        'promo_program',
        'promo_page_events_companies',
        'promo_page_calendar',
        'notes_list',
        'text_chats',
        'result',
        'news',
      ]
    });

    const result = await this.$store.dispatch('_eventStore/createEvent', createData);
    if (!result) {
      this.onSaveEventFail();
      return;
    }

    await this.onSaveEventSuccess(result);

  }

  public onSaveEventFail(): void {
    this.isSentSuccessfully = false;
    this.createdHintText = this.$t('eventPage.create.createError');
    this.isSaveEventButtonDisabled = false;
  }

  public async onSaveEventSuccess(event: TEvent): Promise<void> {

    if (!event) {
      return;
    }

    this.$v.$reset();
    this.isSentSuccessfully = true;
    this.createdHintText = this.$t('eventPage.create.createSuccess');

    this.sendCreateEventToDataLayer();

    await Statistics.createEventStat({
      eventId: event.id,
      referrerUrl: localStorage.getItem('referrer_url'),
      userId: event.creator_user_id
    }, prevRouteInfoForStats);

    await this.patchEventSettings(event.id);
    await this.getEventSettings({ eventId: event.id });

    this.$router.push({ name: 'promo-page-cabinet', params: { eventId: event.id.toFixed(0) } });
  }

  public sendCreateEventToDataLayer(): void {
    try {
      (window as any).dataLayer = (window as any).dataLayer || [];
      ((window as any).dataLayer).push({
        event: 'create-event-form-submit',
        formType: 'create-event',
      });
    } catch {
      /* ignore */
    }
  }
}
